<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="py-2"
      >
        <v-btn-toggle v-model="toggle_day_week">
          <v-btn v-on:click="toggle_day_week = 0">
            Show week
          </v-btn>
          <v-btn v-on:click="toggle_day_week = 1">
            Show day
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="py-2"
      >
        <v-btn-toggle v-model="toggle_jobs">
          <v-btn>
            Show All Jobs
          </v-btn>
          <v-btn>
            Show Planned Jobs
          </v-btn>
          <v-btn>
            Show Converted Jobs
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <GmapMap
      :center="{lat:53, lng:-3}"
      :zoom="7"
      map-type-id="roadmap"
      style="width: 100%; height: 800px"
      >
      <div v-for="(location, index) in locations" :key="index">
        <GmapMarker
          v-if="location.type === 'plannedJob'"
          :position="location.latLng"
          :clickable="true"
          @click="showJob(location.id)"
          :title="location.jobNumber"
          :icon="{url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' }"
        />
      </div>
      <div v-for="(location, index) in locations" :key="'A' + index">
        <GmapMarker
          v-if="location.type === 'convertedJob' && userPermissions.includes(13)"
          :position="location.latLng"
          :clickable="true"
          @click="showConvertedJob(location.id)"
          :title="location.jobNumber"
          :icon="{url: 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png' }"
        />
      </div>
    </GmapMap>
    <planned-job v-if="$store.state.selectedPlannedJob > 0" />
    <selected-job v-if="$store.state.selectedJob > 0" />
  </div>
</template>

<script>
import PlannedJob from '@/components/Jobs/SelectedPlannedJob/SelectedPlannedJob.vue';
import SelectedJob from '@/components/Jobs/SelectedJob/SelectedJob.vue';
import { startOfWeek, format } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'Map',
  components: {
    PlannedJob,
    SelectedJob,
  },
  data() {
    return {
      locations: [],
      toggle_day_week: 0,
      toggle_jobs: 0,
    };
  },
  computed: {
    date() {
      const today = this.$store.state.date;
      const firstDayOfTheWeek = startOfWeek(new Date(today));
      const chosenDate = firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate() + 1);
      return chosenDate;
    },
    fileDate() {
      return format(this.date, 'yyyy/MM/dd');
    },
    singleDate() {
      return format(new Date(this.$store.state.date), 'yyyy/MM/dd');
    },
    token() {
      return this.$store.state.token;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  watch: {
    fileDate() {
      if (this.toggle_day_week === 0) {
        this.locations = [];
        this.getLocations();
      }
    },
    singleDate() {
      if (this.toggle_day_week === 1) {
        this.locations = [];
        this.getLocations();
      }
    },
    toggle_day_week() {
      this.locations = [];
      this.getLocations();
    },
    toggle_jobs() {
      this.locations = [];
      this.getLocations();
    },
  },
  methods: {
    getLocations() {
      const postData = {};
      postData.startDate = this.fileDate;
      postData.singleDate = format(new Date(this.$store.state.date), 'yyyy/MM/dd');
      postData.timeframe = this.toggle_day_week;
      postData.jobtype = this.toggle_jobs;
      axios.post(`/plannedJobs/getLocations.json?token=${this.token}`, postData)
        .then((response) => {
          const locs = response.data;
          for (let i = 0; i < locs.length; i += 1) {
            console.log(locs[i].latitude);
            if (!locs[i].latitude) {
              if (locs[i].address) {
                this.getCoordinates(locs[i].address)
                  .then((res) => {
                    const postDataLL = {};
                    postDataLL.id = locs[i].quotationId;
                    postDataLL.lat = res.lat;
                    postDataLL.lng = res.lng;
                    axios.post(`/quotations/saveLatLng.json?token=${this.token}`, postDataLL);
                    const loc = {};
                    loc.id = locs[i].id;
                    loc.type = locs[i].type;
                    loc.jobNumber = locs[i].jobNumber;
                    loc.latLng = {
                      lat: parseFloat(res.lat, 10),
                      lng: parseFloat(res.lng, 10),
                    };
                    this.locations.push(loc);
                  });
              }
            } else {
              const loc = {};
              loc.id = locs[i].id;
              loc.type = locs[i].type;
              loc.jobNumber = locs[i].jobNumber;
              loc.latLng = {
                lat: parseFloat(locs[i].latitude, 10),
                lng: parseFloat(locs[i].longitude, 10),
              };
              this.locations.push(loc);
            }
          }
        });
    },
    getCoordinates(address) {
      return new Promise((resolve, reject) => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAmR7-xN-glXWhn9zEQilUQAAz9Id9mtaI`)
          .then((response) => response.json())
          .then((data) => {
            let latitude = 0;
            let longitude = 0;
            if (data.results[0]) {
              latitude = data.results[0].geometry.location.lat;
              longitude = data.results[0].geometry.location.lng;
            }
            const coordinates = {};
            coordinates.lat = latitude;
            coordinates.lng = longitude;
            resolve(coordinates);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    showJob(jobId) {
      this.$store.commit('selectPlannedJob', jobId);
    },
    showConvertedJob(jobId) {
      this.$store.commit('selectJob', jobId);
    },
  },
  mounted() {
    this.getLocations();
  },
};
</script>
